import React from 'react';
import { BRAND } from '../../constants/main';
import { DemoVehicleLabelStyledAC } from './AC/DemoVehicleLabelStyled.AC';
import { DemoVehicleLabelStyledOV } from './OV/DemoVehicleLabelStyled.OV';
import { DemoVehicleLabelStyledAP } from './AP/DemoVehicleLabelStyled.AP';
import { DemoVehicleLabelStyledDS } from './DS/DemoVehicleLabelStyled.DS';
import { DemoVehicleLabelStyled } from './DemoVehicleLabelStyled';
import { SC } from '../../styles/theme';
import { VDStatus } from '../../services/stock/models/stockItems.service.model';

export interface DemoVehicleProps extends SC {
    status?: VDStatus;
    isHome?: boolean;
    isTrim?: boolean;
    isBasket?: boolean;
    isCheckout?: boolean;
    mileage?: string;
    registrationDate?: string;
    withMarketingSticker?: boolean;
}

const DemoVehicleLabel = (props: DemoVehicleProps) => {
    if (BRAND === 'AC') return <DemoVehicleLabelStyledAC {...props} />;
    if (BRAND === 'OV') return <DemoVehicleLabelStyledOV {...props} />;
    if (BRAND === 'AP') return <DemoVehicleLabelStyledAP {...props} />;
    if (BRAND === 'DS') return <DemoVehicleLabelStyledDS {...props} />;
    return <DemoVehicleLabelStyled {...props} />;
};

export default DemoVehicleLabel;
