import styled, { css } from 'styled-components';
import AlertTemplate from './DemoVehicleLabelTemplate';
import { rem } from 'polished';

export const DemoVehicleLabelStyled = styled(AlertTemplate)`
    ${({ isTrim }) =>
        isTrim &&
        css`
            position: absolute;
            top: ${rem(20)};
            right: ${rem(20)};
        `}

    ${({ isHome, withMarketingSticker }) =>
        isHome &&
        css`
            position: absolute;
            right: ${rem(15)};
            top: ${withMarketingSticker ? rem(150) : rem(50)};
            display: flex;
        `}

    ${({ isBasket }) =>
        isBasket &&
        css`
            margin-top: ${rem(20)};
            margin-bottom: ${rem(10)};
        `}
    ${({ isCheckout }) =>
        isCheckout &&
        css`
            margin-top: ${rem(6)};
            text-align: right;
            flex-direction: column;
            align-items: center !important;
        `}

    .info {
        display: inline-flex;
        align-items: center;
        padding: ${rem(4)} ${rem(8)};
        gap: ${rem(8)};
        cursor: pointer;
    }

    .details {
        display: inline-flex;
        gap: ${rem(10)};
        margin-top: ${rem(10)};

        &-label {
            padding-right: ${rem(5)};
        }
    }
`;
